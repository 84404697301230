import { default as authentication_45settingsRx1Dt72mhRMeta } from "/vercel/path0/pages/advanced/authentication-settings.vue?macro=true";
import { default as indexV3PGhZhZLKMeta } from "/vercel/path0/pages/advanced/index.vue?macro=true";
import { default as authorizationKmbaY4ATw0Meta } from "/vercel/path0/pages/advanced/integrations/itsacheckmate/oauth2/authorization.vue?macro=true";
import { default as authorization6Y5Bux3OWeMeta } from "/vercel/path0/pages/advanced/permissions/authorization.vue?macro=true";
import { default as indexycpEjpTVdjMeta } from "/vercel/path0/pages/advanced/permissions/index.vue?macro=true";
import { default as print_45ticketyfBYjfey9lMeta } from "/vercel/path0/pages/advanced/print-ticket.vue?macro=true";
import { default as printer_45configurationNEL0U5W2TlMeta } from "/vercel/path0/pages/advanced/printer-configuration.vue?macro=true";
import { default as stylingwtZg0xNyfHMeta } from "/vercel/path0/pages/advanced/styling.vue?macro=true";
import { default as theme_45expressf0VBwq7NjJMeta } from "/vercel/path0/pages/advanced/theme-express.vue?macro=true";
import { default as billing_45setup_45success00XTI3BnnuMeta } from "/vercel/path0/pages/billing-setup-success.vue?macro=true";
import { default as billingZJimigxeyHMeta } from "/vercel/path0/pages/billing.vue?macro=true";
import { default as brandingXsDwwHuO3AMeta } from "/vercel/path0/pages/branding.vue?macro=true";
import { default as going_45live_45checklistq5ImggWE3yMeta } from "/vercel/path0/pages/going-live-checklist.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexbKJcjR7tRKMeta } from "/vercel/path0/pages/items/[itemId]/customizations/[customizationId]/index.vue?macro=true";
import { default as indexwe4MFiQOOfMeta } from "/vercel/path0/pages/items/[itemId]/customizations/index.vue?macro=true";
import { default as index7PUjkCgy59Meta } from "/vercel/path0/pages/items/[itemId]/index.vue?macro=true";
import { default as indexZhsPknemarMeta } from "/vercel/path0/pages/items/index.vue?macro=true";
import { default as logisticsgPFUJEUU45Meta } from "/vercel/path0/pages/logistics.vue?macro=true";
import { default as marketingI0slZEbTpZMeta } from "/vercel/path0/pages/marketing.vue?macro=true";
import { default as paymentsZySyIcChhuMeta } from "/vercel/path0/pages/payments.vue?macro=true";
import { default as printerWfS90aLKdBMeta } from "/vercel/path0/pages/printer.vue?macro=true";
import { default as _91_46_46_46codeProvider_93oNyKCCWVCmMeta } from "/vercel/path0/pages/registration/[...codeProvider].vue?macro=true";
import { default as scheduleUeJs4jgk5MMeta } from "/vercel/path0/pages/schedule.vue?macro=true";
import { default as sign_45innR2QzqahJfMeta } from "/vercel/path0/pages/sign-in.vue?macro=true";
import { default as sign_45out2UnRZOBkU6Meta } from "/vercel/path0/pages/sign-out.vue?macro=true";
import { default as sign_45upHNIilXjClyMeta } from "/vercel/path0/pages/sign-up.vue?macro=true";
import { default as _91transactionUUID_93qnEE2BgsU6Meta } from "/vercel/path0/pages/transactions/[transactionUUID].vue?macro=true";
import { default as indexKI4B4Rtk57Meta } from "/vercel/path0/pages/transactions/index.vue?macro=true";
import { default as _91pageIndex_93HTU143SKyxMeta } from "/vercel/path0/pages/transactions/pages/[pageIndex].vue?macro=true";
export default [
  {
    name: authentication_45settingsRx1Dt72mhRMeta?.name ?? "advanced-authentication-settings",
    path: authentication_45settingsRx1Dt72mhRMeta?.path ?? "/advanced/authentication-settings",
    meta: authentication_45settingsRx1Dt72mhRMeta || {},
    alias: authentication_45settingsRx1Dt72mhRMeta?.alias || [],
    redirect: authentication_45settingsRx1Dt72mhRMeta?.redirect,
    component: () => import("/vercel/path0/pages/advanced/authentication-settings.vue").then(m => m.default || m)
  },
  {
    name: indexV3PGhZhZLKMeta?.name ?? "advanced",
    path: indexV3PGhZhZLKMeta?.path ?? "/advanced",
    meta: indexV3PGhZhZLKMeta || {},
    alias: indexV3PGhZhZLKMeta?.alias || [],
    redirect: indexV3PGhZhZLKMeta?.redirect,
    component: () => import("/vercel/path0/pages/advanced/index.vue").then(m => m.default || m)
  },
  {
    name: authorizationKmbaY4ATw0Meta?.name ?? "advanced-integrations-itsacheckmate-oauth2-authorization",
    path: authorizationKmbaY4ATw0Meta?.path ?? "/advanced/integrations/itsacheckmate/oauth2/authorization",
    meta: authorizationKmbaY4ATw0Meta || {},
    alias: authorizationKmbaY4ATw0Meta?.alias || [],
    redirect: authorizationKmbaY4ATw0Meta?.redirect,
    component: () => import("/vercel/path0/pages/advanced/integrations/itsacheckmate/oauth2/authorization.vue").then(m => m.default || m)
  },
  {
    name: authorization6Y5Bux3OWeMeta?.name ?? "advanced-permissions-authorization",
    path: authorization6Y5Bux3OWeMeta?.path ?? "/advanced/permissions/authorization",
    meta: authorization6Y5Bux3OWeMeta || {},
    alias: authorization6Y5Bux3OWeMeta?.alias || [],
    redirect: authorization6Y5Bux3OWeMeta?.redirect,
    component: () => import("/vercel/path0/pages/advanced/permissions/authorization.vue").then(m => m.default || m)
  },
  {
    name: indexycpEjpTVdjMeta?.name ?? "advanced-permissions",
    path: indexycpEjpTVdjMeta?.path ?? "/advanced/permissions",
    meta: indexycpEjpTVdjMeta || {},
    alias: indexycpEjpTVdjMeta?.alias || [],
    redirect: indexycpEjpTVdjMeta?.redirect,
    component: () => import("/vercel/path0/pages/advanced/permissions/index.vue").then(m => m.default || m)
  },
  {
    name: print_45ticketyfBYjfey9lMeta?.name ?? "advanced-print-ticket",
    path: print_45ticketyfBYjfey9lMeta?.path ?? "/advanced/print-ticket",
    meta: print_45ticketyfBYjfey9lMeta || {},
    alias: print_45ticketyfBYjfey9lMeta?.alias || [],
    redirect: print_45ticketyfBYjfey9lMeta?.redirect,
    component: () => import("/vercel/path0/pages/advanced/print-ticket.vue").then(m => m.default || m)
  },
  {
    name: printer_45configurationNEL0U5W2TlMeta?.name ?? "advanced-printer-configuration",
    path: printer_45configurationNEL0U5W2TlMeta?.path ?? "/advanced/printer-configuration",
    meta: printer_45configurationNEL0U5W2TlMeta || {},
    alias: printer_45configurationNEL0U5W2TlMeta?.alias || [],
    redirect: printer_45configurationNEL0U5W2TlMeta?.redirect,
    component: () => import("/vercel/path0/pages/advanced/printer-configuration.vue").then(m => m.default || m)
  },
  {
    name: stylingwtZg0xNyfHMeta?.name ?? "advanced-styling",
    path: stylingwtZg0xNyfHMeta?.path ?? "/advanced/styling",
    meta: stylingwtZg0xNyfHMeta || {},
    alias: stylingwtZg0xNyfHMeta?.alias || [],
    redirect: stylingwtZg0xNyfHMeta?.redirect,
    component: () => import("/vercel/path0/pages/advanced/styling.vue").then(m => m.default || m)
  },
  {
    name: theme_45expressf0VBwq7NjJMeta?.name ?? "advanced-theme-express",
    path: theme_45expressf0VBwq7NjJMeta?.path ?? "/advanced/theme-express",
    meta: theme_45expressf0VBwq7NjJMeta || {},
    alias: theme_45expressf0VBwq7NjJMeta?.alias || [],
    redirect: theme_45expressf0VBwq7NjJMeta?.redirect,
    component: () => import("/vercel/path0/pages/advanced/theme-express.vue").then(m => m.default || m)
  },
  {
    name: billing_45setup_45success00XTI3BnnuMeta?.name ?? "billing-setup-success",
    path: billing_45setup_45success00XTI3BnnuMeta?.path ?? "/billing-setup-success",
    meta: billing_45setup_45success00XTI3BnnuMeta || {},
    alias: billing_45setup_45success00XTI3BnnuMeta?.alias || [],
    redirect: billing_45setup_45success00XTI3BnnuMeta?.redirect,
    component: () => import("/vercel/path0/pages/billing-setup-success.vue").then(m => m.default || m)
  },
  {
    name: billingZJimigxeyHMeta?.name ?? "billing",
    path: billingZJimigxeyHMeta?.path ?? "/billing",
    meta: billingZJimigxeyHMeta || {},
    alias: billingZJimigxeyHMeta?.alias || [],
    redirect: billingZJimigxeyHMeta?.redirect,
    component: () => import("/vercel/path0/pages/billing.vue").then(m => m.default || m)
  },
  {
    name: brandingXsDwwHuO3AMeta?.name ?? "branding",
    path: brandingXsDwwHuO3AMeta?.path ?? "/branding",
    meta: brandingXsDwwHuO3AMeta || {},
    alias: brandingXsDwwHuO3AMeta?.alias || [],
    redirect: brandingXsDwwHuO3AMeta?.redirect,
    component: () => import("/vercel/path0/pages/branding.vue").then(m => m.default || m)
  },
  {
    name: going_45live_45checklistq5ImggWE3yMeta?.name ?? "going-live-checklist",
    path: going_45live_45checklistq5ImggWE3yMeta?.path ?? "/going-live-checklist",
    meta: going_45live_45checklistq5ImggWE3yMeta || {},
    alias: going_45live_45checklistq5ImggWE3yMeta?.alias || [],
    redirect: going_45live_45checklistq5ImggWE3yMeta?.redirect,
    component: () => import("/vercel/path0/pages/going-live-checklist.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexbKJcjR7tRKMeta?.name ?? "items-itemId-customizations-customizationId",
    path: indexbKJcjR7tRKMeta?.path ?? "/items/:itemId()/customizations/:customizationId()",
    meta: indexbKJcjR7tRKMeta || {},
    alias: indexbKJcjR7tRKMeta?.alias || [],
    redirect: indexbKJcjR7tRKMeta?.redirect,
    component: () => import("/vercel/path0/pages/items/[itemId]/customizations/[customizationId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwe4MFiQOOfMeta?.name ?? "items-itemId-customizations",
    path: indexwe4MFiQOOfMeta?.path ?? "/items/:itemId()/customizations",
    meta: indexwe4MFiQOOfMeta || {},
    alias: indexwe4MFiQOOfMeta?.alias || [],
    redirect: indexwe4MFiQOOfMeta?.redirect,
    component: () => import("/vercel/path0/pages/items/[itemId]/customizations/index.vue").then(m => m.default || m)
  },
  {
    name: index7PUjkCgy59Meta?.name ?? "items-itemId",
    path: index7PUjkCgy59Meta?.path ?? "/items/:itemId()",
    meta: index7PUjkCgy59Meta || {},
    alias: index7PUjkCgy59Meta?.alias || [],
    redirect: index7PUjkCgy59Meta?.redirect,
    component: () => import("/vercel/path0/pages/items/[itemId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZhsPknemarMeta?.name ?? "items",
    path: indexZhsPknemarMeta?.path ?? "/items",
    meta: indexZhsPknemarMeta || {},
    alias: indexZhsPknemarMeta?.alias || [],
    redirect: indexZhsPknemarMeta?.redirect,
    component: () => import("/vercel/path0/pages/items/index.vue").then(m => m.default || m)
  },
  {
    name: logisticsgPFUJEUU45Meta?.name ?? "logistics",
    path: logisticsgPFUJEUU45Meta?.path ?? "/logistics",
    meta: logisticsgPFUJEUU45Meta || {},
    alias: logisticsgPFUJEUU45Meta?.alias || [],
    redirect: logisticsgPFUJEUU45Meta?.redirect,
    component: () => import("/vercel/path0/pages/logistics.vue").then(m => m.default || m)
  },
  {
    name: marketingI0slZEbTpZMeta?.name ?? "marketing",
    path: marketingI0slZEbTpZMeta?.path ?? "/marketing",
    meta: marketingI0slZEbTpZMeta || {},
    alias: marketingI0slZEbTpZMeta?.alias || [],
    redirect: marketingI0slZEbTpZMeta?.redirect,
    component: () => import("/vercel/path0/pages/marketing.vue").then(m => m.default || m)
  },
  {
    name: paymentsZySyIcChhuMeta?.name ?? "payments",
    path: paymentsZySyIcChhuMeta?.path ?? "/payments",
    meta: paymentsZySyIcChhuMeta || {},
    alias: paymentsZySyIcChhuMeta?.alias || [],
    redirect: paymentsZySyIcChhuMeta?.redirect,
    component: () => import("/vercel/path0/pages/payments.vue").then(m => m.default || m)
  },
  {
    name: printerWfS90aLKdBMeta?.name ?? "printer",
    path: printerWfS90aLKdBMeta?.path ?? "/printer",
    meta: printerWfS90aLKdBMeta || {},
    alias: printerWfS90aLKdBMeta?.alias || [],
    redirect: printerWfS90aLKdBMeta?.redirect,
    component: () => import("/vercel/path0/pages/printer.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46codeProvider_93oNyKCCWVCmMeta?.name ?? "registration-codeProvider",
    path: _91_46_46_46codeProvider_93oNyKCCWVCmMeta?.path ?? "/registration/:codeProvider(.*)*",
    meta: _91_46_46_46codeProvider_93oNyKCCWVCmMeta || {},
    alias: _91_46_46_46codeProvider_93oNyKCCWVCmMeta?.alias || [],
    redirect: _91_46_46_46codeProvider_93oNyKCCWVCmMeta?.redirect,
    component: () => import("/vercel/path0/pages/registration/[...codeProvider].vue").then(m => m.default || m)
  },
  {
    name: scheduleUeJs4jgk5MMeta?.name ?? "schedule",
    path: scheduleUeJs4jgk5MMeta?.path ?? "/schedule",
    meta: scheduleUeJs4jgk5MMeta || {},
    alias: scheduleUeJs4jgk5MMeta?.alias || [],
    redirect: scheduleUeJs4jgk5MMeta?.redirect,
    component: () => import("/vercel/path0/pages/schedule.vue").then(m => m.default || m)
  },
  {
    name: sign_45innR2QzqahJfMeta?.name ?? "sign-in",
    path: sign_45innR2QzqahJfMeta?.path ?? "/sign-in",
    meta: sign_45innR2QzqahJfMeta || {},
    alias: sign_45innR2QzqahJfMeta?.alias || [],
    redirect: sign_45innR2QzqahJfMeta?.redirect,
    component: () => import("/vercel/path0/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: sign_45out2UnRZOBkU6Meta?.name ?? "sign-out",
    path: sign_45out2UnRZOBkU6Meta?.path ?? "/sign-out",
    meta: sign_45out2UnRZOBkU6Meta || {},
    alias: sign_45out2UnRZOBkU6Meta?.alias || [],
    redirect: sign_45out2UnRZOBkU6Meta?.redirect,
    component: () => import("/vercel/path0/pages/sign-out.vue").then(m => m.default || m)
  },
  {
    name: sign_45upHNIilXjClyMeta?.name ?? "sign-up",
    path: sign_45upHNIilXjClyMeta?.path ?? "/sign-up",
    meta: sign_45upHNIilXjClyMeta || {},
    alias: sign_45upHNIilXjClyMeta?.alias || [],
    redirect: sign_45upHNIilXjClyMeta?.redirect,
    component: () => import("/vercel/path0/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: _91transactionUUID_93qnEE2BgsU6Meta?.name ?? "transactions-transactionUUID",
    path: _91transactionUUID_93qnEE2BgsU6Meta?.path ?? "/transactions/:transactionUUID()",
    meta: _91transactionUUID_93qnEE2BgsU6Meta || {},
    alias: _91transactionUUID_93qnEE2BgsU6Meta?.alias || [],
    redirect: _91transactionUUID_93qnEE2BgsU6Meta?.redirect,
    component: () => import("/vercel/path0/pages/transactions/[transactionUUID].vue").then(m => m.default || m)
  },
  {
    name: indexKI4B4Rtk57Meta?.name ?? "transactions",
    path: indexKI4B4Rtk57Meta?.path ?? "/transactions",
    meta: indexKI4B4Rtk57Meta || {},
    alias: indexKI4B4Rtk57Meta?.alias || [],
    redirect: indexKI4B4Rtk57Meta?.redirect,
    component: () => import("/vercel/path0/pages/transactions/index.vue").then(m => m.default || m)
  },
  {
    name: _91pageIndex_93HTU143SKyxMeta?.name ?? "transactions-pages-pageIndex",
    path: _91pageIndex_93HTU143SKyxMeta?.path ?? "/transactions/pages/:pageIndex()",
    meta: _91pageIndex_93HTU143SKyxMeta || {},
    alias: _91pageIndex_93HTU143SKyxMeta?.alias || [],
    redirect: _91pageIndex_93HTU143SKyxMeta?.redirect,
    component: () => import("/vercel/path0/pages/transactions/pages/[pageIndex].vue").then(m => m.default || m)
  }
]