const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export default defineNuxtPlugin((nuxtApp) => {
  const savingCrashed = useState('savingCrashed');
  const toast = useToast()
  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    console.log(JSON.stringify(error, Object.getOwnPropertyNames(error)))
    savingCrashed.value = true
    if (error.message === 'Unable to transform response from server') {
      toast.add({
        title: 'Authentication Expired (E11)',
        description: 'Refreshing...',
        color: 'red',
        timeout: 2000,
        callback: () =>  window.location.reload()
      })
      return
    }
    if (error.message === 'Unauthenticated') {
      toast.add({
        title: 'Authentication Expired (E12)',
        description: 'Refreshing...',
        color: 'red',
        timeout: 2000,
        callback: () =>  window.location.reload()
      })
      return
    }
    if (isJson(error.message)) { // zod error
      toast.add({
        title: JSON.parse(error.message)[0].message,
        color: 'red',
      })
      return
    }
    toast.add({
      title: error.message,
      color: 'red',
    })
  }
})