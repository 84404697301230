import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.0_@parcel+watcher@2.4.0_@planetscale+database@1.16.0_@types+node@20.11.16_bufferuti_ja5hatueqoolppzeiim4bzlgs4/node_modules/nuxt/dist/pages/runtime/validate.js";
import save_45work_45before_45leave_45global from "/vercel/path0/middleware/saveWorkBeforeLeave.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.0_@parcel+watcher@2.4.0_@planetscale+database@1.16.0_@types+node@20.11.16_bufferuti_ja5hatueqoolppzeiim4bzlgs4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  save_45work_45before_45leave_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "better-logged-in": () => import("/vercel/path0/middleware/better-logged-in.ts"),
  "hanko-logged-in": () => import("/vercel/path0/node_modules/.pnpm/@nuxtjs+hanko@0.5.0_rollup@4.9.6/node_modules/@nuxtjs/hanko/dist/runtime/middleware/logged-in.mjs"),
  "hanko-logged-out": () => import("/vercel/path0/node_modules/.pnpm/@nuxtjs+hanko@0.5.0_rollup@4.9.6/node_modules/@nuxtjs/hanko/dist/runtime/middleware/logged-out.mjs")
}