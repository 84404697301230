export default defineNuxtRouteMiddleware((to, from) => {
  if (useState('uncommittedChanges').value) {
    const response = window.confirm('You have unsaved changes. Would you like to discard changes?')
    if (!response) {
      return abortNavigation()
    } else {
      useState<{ [key: string]: boolean }>('unsavedChangesStack').value = {}
      refreshNuxtData()
    }
  }
})