<script lang="ts" setup>
import LogRocket from 'logrocket';
LogRocket.init('kq0wfw/orderdive-dashboard');
const nuxtApp = useNuxtApp()
nuxtApp.hook("page:finish", () => {
   window.scrollTo(0, 0)
})
</script>
<template>
  <div class="dsv1__Page dsv1__Text">
    <NuxtLayout>
      <NuxtPage :key="$route.fullPath"/><!-- https://github.com/nuxt/framework/issues/3141 -->
    </NuxtLayout>
    <UNotifications />
  </div>
</template>
<style lang="scss">
@import "./assets/scss/animations.scss";
.o-notices {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  pointer-events: none;
  padding: 2em;
  z-index: 1000;
}
.o-notices--top {
  flex-direction: column;
}
.o-notification {
  align-self: flex-end;
  @apply mb-3;
}

@keyframes skeleton-loading {
  0% { background-position: 100% 50% }
  100% { background-position: 0 50% }
}
$grey-lighter: #dbdbdb;
.skeleton {
  background: linear-gradient(90deg, #dbdbdb 25%, rgba(219, 219, 219, 0.5) 50%, #cccccc 75%), linear-gradient(90deg, rgba(219, 219, 219, 0.5) 25%, rgba(120, 110, 110, 0.5) 50%, rgba(219, 219, 219, 0.5) 75%);
  background-size: 400% 100%;
  animation-name: skeleton-loading;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
}
.dark .skeleton {
  background: var(linear-gradient(90deg, $grey-lighter 25%, rgba($grey-lighter, 0.5) 50%, \$grey-lighter 75%),linear-gradient(90deg,rgba(0, 0, 0, 0.5) 25%,rgba(70, 70, 70, 0.5) 50%,rgba(0, 0, 0, 0.5) 75%));
  background-size: 400% 100%;
  animation-name: skeleton-loading;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
}

.list-transition-move, /* apply transition to moving elements */
.list-transition-enter-active,
.list-transition-leave-active {
  transition: all 0.5s ease;
}

.list-transition-enter-from,
.list-transition-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-transition-leave-active {
  position: absolute;
}


.horizontal-transition-move, /* apply transition to moving elements */
.horizontal-transition-enter-active,
.horizontal-transition-leave-active {
  transition: all 0.5s ease;
}

.horizontal-transition-enter-from,
.horizontal-transition-leave-to {
  opacity: 0;
  transform: translateY(100px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.horizontal-transition-leave-active {
  position: absolute;
}

html {
  font-family: Inter,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}
body {
  @apply bg-gray-50;
}

:root {
  --item-height: 2.5rem;
  --item-margin: 1rem 0;
  --brand-color-shade-1: rgb(29 78 216);
  --link-color: rgb(29 78 216);
  --container-padding: none;
}
hanko-profile::part(headline1),
hanko-auth::part(headline1) {
  display: none;
}
hanko-profile::part(divider-line),
hanko-auth::part(divider-line) {
  opacity: .2;
}
hanko-profile::part(primary-button),
hanko-auth::part(primary-button) {
  @apply text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2.5 focus:outline-none;
}
hanko-profile::part(secondary-button),
hanko-auth::part(secondary-button) {
  @apply py-2.5 px-5 text-sm font-bold text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-blue-700 focus:z-[10] focus:ring-4 focus:ring-gray-200;
}
hanko-profile::part(text-input),
hanko-auth::part(text-input) {
  @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5;
}
hanko-profile::part(text-input):focus,
hanko-auth::part(text-input):focus {
  box-shadow: 0 0 0 calc(1px + 0px ) rgb(37 99 235 / 1 );
}
/* <Sign up specific> */
hanko-auth.signUp::part(divider) {
  display: none;
}
hanko-auth.signUp::part(secondary-button) {
  display: none;
}
/* </Sign up specific> */
</style>
<style>


.dsv1__PrimaryButton {
  @apply inline-flex items-center whitespace-nowrap cursor-pointer rounded-[0.5rem] text-[#ffffff] py-2 px-4 bg-[#2563EB] hover:bg-[#1d4fbc] active:bg-[#123175] active:scale-90 origin-center transform transition-all duration-100;
}
.dsv1__SecondaryButton {
  @apply inline-flex whitespace-nowrap cursor-pointer rounded-[0.5rem] hover:bg-black focus:outline-none py-2 px-4 hover:bg-opacity-20 active:opacity-10;
}
.dsv1__DangerButton {
  @apply whitespace-nowrap cursor-pointer rounded-[0.5rem] text-[#ffffff] py-2 px-4 bg-[#ef4444] hover:bg-[#bf3636] active:bg-[#772222] active:scale-90 origin-center transform transition-all duration-100;
}
.dsv1__Page {
  @apply text-black bg-gray-50
}
.dsv1__Link {
  @apply text-blue-400 underline underline-offset-[6px];
}
.dsv1__OpenBagButton {
  @apply text-[#ffffff] bg-[#2563EB] hover:bg-[#1d4fbc] active:bg-[#123175] inline-flex justify-center items-center focus:outline-none text-2xl py-6 px-8 bottom-0 left-0 z-20 sm:text-xl rounded-tr-3xl sm:rounded-[0.5rem];
}
.dsv1__OpenBagButton__Icon {
  @apply mr-4;
}
.dsv1__Platter {
  @apply text-black bg-white border border-zinc-300 rounded-lg
}
.dsv1__Platter__Body {
  @apply p-4;
}
.dsv1__Platter__Footer {
  @apply bg-zinc-100 p-4 rounded-b-lg flex justify-end
}
.dsv1__MultiSelectOption {
  @apply flex items-center bg-white py-[0.75rem] px-[1.1rem] rounded-lg shadow active:shadow-none select-none text-lg transition-all duration-100 cursor-pointer border border-gray-200
}
.dsv1__MultiSelectOption__active {
  @apply transform translate-y-[-2px] shadow-md;
}
.dsv1__MultiSelectOption__StateIcon {
  @apply inline-block mr-2;
}
.dsv1__Input__Wrapper__Label {
  @apply text-sm;
}
.dsv1__Input__Wrapper__Inner {
  @apply rounded-[0.5rem] relative focus-within:z-[2] text-black focus-within:ring-[#0275ff] items-center focus-within:ring-1 border border-gray-300 flex;
}
.dsv1__Input__Wrapper__Inner__Prefix {
  @apply rounded-l-[0.5rem] leading-7 bg-gray-200 p-2 px-3;
}
.dsv1__Input__Wrapper__Inner__Input {
  @apply focus:outline-none focus:ring-0 leading-7 ring-0 border-none bg-transparent cursor-pointer p-2 px-3 w-full;
}
.dsv1__Input__Wrapper__Inner__Suffix {
  @apply rounded-r-[0.5rem] leading-7 bg-gray-200 p-2 px-3;
}
.dsv1__Input__Help {
  @apply text-sm opacity-60;
}
.dsv1__Input__Messages {
  @apply text-red-400 text-sm mt-[1rem];
}
.dsv1__Toggle {
  @apply relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-[0.5rem] cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2563EB];
}
.dsv1__Toggle__active {
  @apply bg-[#2563EB];
}
.dsv1__Toggle__inactive {
  @apply bg-gray-200
}
.dsv1__Toggle__ToggleCenter {
  @apply rounded-[0.5rem] bg-white;
}
.dsv1__ImageUploader__ClearTrigger {
  @apply text-[#ef4444];
}
.dsv1__Checkbox {
  @apply h-6 w-6 cursor-pointer focus:ring-[#2563EB] text-[#2563EB] border-gray-300 rounded
}
.dsv1__Dropdown {
  @apply rounded-[0.5rem] text-black bg-white focus-within:ring-[#2563EB] inline-flex items-center focus-within:ring-2 border border-gray-300 overflow-hidden px-3 py-2;
}
.dsv1__Dropdown__Menu {
  @apply absolute z-10 overflow-hidden shadow-lg border-l border-r border-gray-200 mt-1 bg-white rounded-[0.5rem] shadow-lg border-l border-r border-gray-200 text-black
}
.dsv1__Dropdown__Menu__DropdownItem {
  @apply hover:text-black p-2 hover:bg-[#efefef] active:bg-[#ccc] cursor-pointer;
}
.dsv1__Dropdown__Menu__DropdownItem__selected {
  @apply bg-[#2563EB] text-[#ffffff] hover:bg-[#1d4fbc] font-bold;
}
.dsv1__Navigation {
  @apply text-black bg-white border border-zinc-300 rounded-none
}
.dsv1__Navigation__NavigationLogo {
  @apply h-8 w-auto;
}
.dsv1__Navigation__NavigationItem {
  @apply cursor-pointer w-full hover:bg-white py-2 px-3 flex items-center rounded-[0.5rem] hover:bg-gray-50;
}
.dsv1__Navigation__NavigationItem__current {
  @apply bg-[#2563EB] text-[#ffffff] hover:bg-[#2563EB];
}
.dsv1__Navigation__NavigationUser {
  @apply p-4;
}
.dsv1__Navigation__NavigationUser__NavigationUserEmail {
  @apply text-xs opacity-50;
}
.dsv1__Navigation__NavigationUser__NavigationUserOrg {
  @apply text-sm;
}
.dsv1__Toast {
  @apply z-40 top-6 right-6 bg-gray-800 text-white w-60 flex items-center rounded-[0.5rem] p-4 border-l-8 flex
}
.dsv1__Toast__error {
  @apply border-[#ef4444];
}
.dsv1__Toast__success {
  @apply border-[#22c55e];
}
.dsv1__Toast__neutral {
  @apply border-[#0ea5e9];
}
.dsv1__Radio {
  @apply w-6 h-6 focus:ring-[#2563EB] text-[#2563EB] border-gray-300;
}
.dsv1__Radio__Label {
  @apply ml-3 block text-sm font-medium;
}
.dsv1__ToolbarNavigation {
  @apply bg-[#2563EB] text-[#ffffff] sticky top-0 shadow-xl;
}
.dsv1__ToolbarNavigation__Tabs__Tab {
  @apply inline-flex items-center pb-1 mb-2 px-1 border-b-2 font-medium text-sm cursor-pointer border-transparent;
}
.dsv1__ToolbarNavigation__Tabs__Tab__active {
  @apply border-[#2563EB];
}
.dsv1__ToolbarNavigation__Select {
  @apply shadow-sm focus:outline-none rounded-[0.5rem] text-black focus-within:ring-[#2563EB] shadow inline-flex items-center focus-within:ring-2 border-t border-gray-200 overflow-hidden bg-[#ffffffcc] p-2 px-3 appearance-none;
}
.dsv1__ToolbarNavigation__Input {
  @apply cursor-pointer border border-gray-200 focus:outline-none rounded-[0.5rem] text-black bg-[#ffffffcc] focus:border-[#2563EB] shadow overflow-hidden;
}
.dsv1__ToolbarNavigation__Input__Prefix {
  @apply leading-7 p-2 px-3;
}
.dsv1__ToolbarNavigation__Input__Suffix {
  @apply leading-7 p-2 px-3;
}
.dsv1__ToolbarNavigation__Input__InnerInput {
  @apply leading-7 ring-0 shadow-none focus:shadow-none border-none focus:outline-none bg-transparent cursor-pointer focus:cursor-auto p-2 px-3;
}
.dsv1__ToolbarNavigation__Input__Clear {
  @apply text-white text-xs ml-4 cursor-pointer;
}
.dsv1__BrandingHeader__Info {
  @apply text-sm flex;
}
.dsv1__BrandingHeader__BrandName {
  @apply text-3xl lg:text-5xl;
}
.dsv1__BrandingHeader__Logo {
  @apply max-h-20;
}
.dsv1__Money {
  @apply text-green-600 font-bold font-mono;
}
.dsv1__Money__negative {
  @apply text-red-500 font-mono;
}
.dsv1__MoneyAddon {
  @apply text-green-600 font-bold font-mono;
}
.dsv1__MoneyAddon__negative {
  @apply text-red-500 font-mono;
}
.dsv1__Datepicker {
  @apply flex justify-center flex-col items-center;
}
.dsv1__Datepicker__TableHead {
  @apply grid grid-cols-7 w-full grid-rows-[2.5rem] justify-center font-bold;
}
.dsv1__Datepicker__TableHead__HeaderButtons {
  @apply flex items-center justify-center;
}
.dsv1__Datepicker__TableHead__HeaderButtons__PrevBtn {
  order: 0;
  @apply mr-2;
}
.dsv1__Datepicker__TableHead__HeaderButtons__NextBtn {
  order: 2;
  @apply ml-2;
}
.dsv1__Datepicker__TableHead__HeaderButtons__DayMonthSelects {
  order: 1;
  @apply flex;
}
.dsv1__Datepicker__TableHead__HeaderButtons__DayMonthSelect {
  @apply py-2 m-2 rounded-lg border-none shadow-none bg-transparent px-4 focus:ring-0 bg-none;
}
.dsv1__Datepicker__TableRow {
  @apply grid grid-cols-7 w-full grid-rows-[2.5rem] justify-center;
}
.dsv1__Datepicker__TableRow__Day {
  @apply flex justify-center items-center px-3;
}
.dsv1__Datepicker__TableRow__Day__selectable {
  @apply hover:bg-[#2563EB] hover:text-white;
}
.dsv1__Datepicker__TableRow__Day__selected {
  @apply bg-[#2563EB] text-white;
}
.dsv1__Datepicker__TableRow__Day__unselectable {
  @apply opacity-25;
}
.dsv1__ItemTile {
  @apply text-left relative md:text-center transition-shadow border-r md:max-w-[15rem] border-none select-none inline-block w-full flex md:flex-col flex-row max-w-none text-white bg-gray-700 relative rounded-[0.5rem] overflow-hidden;
}
.dsv1__ItemTile__Overlay {
  @apply bg-black w-full h-full absolute z-[2] opacity-0 cursor-pointer hover:opacity-20 active:opacity-10;
}
.dsv1__ItemTile__ProductImage {
  @apply object-cover md:max-h-full md:h-full md:w-full w-24 h-24;
}
.dsv1__ItemTile__TextWrapper {
  @apply flex flex-1 relative justify-center items-start md:items-center flex-col ml-8 md:m-0 md:pt-5 md:px-3 mr-4;
}
.dsv1__ItemTile__TextWrapper__Edge {
  @apply text-gray-700 fill-current absolute w-full h-12 -top-12;
}
.dsv1__ItemTile__TextWrapper__Title {
  @apply text-lg mb-2;
}
.dsv1__ItemTile__TextWrapper__Price {
  @apply px-3 py-2 text-[#00d500] text-sm bg-gray-800 text-green-200 md:mb-2;
}
.dsv1__Heading1 {
  @apply text-[2.5rem] font-bold;
}
.dsv1__Heading2 {
  @apply text-3xl font-bold;
}
.dsv1__Heading3 {
  @apply text-2xl font-bold;
}
.dsv1__BagItem {
  @apply list-none border-b-2 border-dashed border-gray-200 flex mb-4 pb-4 relative;
}
.dsv1__BagItem__ThumbnailWrapper {
  @apply overflow-hidden relative overflow-hidden rounded-xl w-24 h-24;
}
.dsv1__BagItem__ThumbnailWrapper__QuantitySelect {
  @apply text-center p-2 absolute top-0 bg-none left-0 border-none cursor-pointer inline-block text-xs font-semibold bg-[#2563EB] rounded-br-xl text-[#ffffff] appearance-none;
}
.dsv1__BagItem__ThumbnailWrapper__Thumbnail {
  @apply object-contain w-24;
}
.dsv1__Table {
  @apply text-xs;
}
.dsv1__Table__Thead {
  @apply uppercase whitespace-nowrap;
}
.dsv1__Table__Thead__Tr {
  @apply bg-gray-200
}
.dsv1__Table__Thead__Tr__Th {
  @apply px-4 py-2;
}
.dsv1__Table__Tbody {
  @apply divide-y;
}
.dsv1__Table__Tbody__Tr__Td {
  @apply py-4;
}
.dsv1__Table__Tbody__Tr__Th {
  @apply underline;
}
.dsv1__Chip {
  @apply px-2 py-[.125rem] bg-[#2563EB] text-[#ffffff] text-sm inline-block;
}
.dsv1__IconButton {
  @apply hover:opacity-50;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinning-icon {
  animation: spin 300ms linear infinite;
}
</style>