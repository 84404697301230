import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  
  Switch,
  SwitchGroup,
  SwitchLabel,
} from '@headlessui/vue'


const components = {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  
  Switch,
  SwitchGroup,
  SwitchLabel,
}
export default defineNuxtPlugin((nuxtApp) => {
  for (const [componentName, component] of Object.entries(components)) {
    nuxtApp.vueApp.component(componentName, component)
  }
})