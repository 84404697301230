// import { createProPlugin, autocomplete } from '@formkit/pro'
// import { generateClasses } from '@formkit/themes'
// // import "@formkit/themes/genesis"
// // import '@formkit/pro/genesis'
// const pro = createProPlugin('fk-66f40b8a84', {
//   autocomplete,
// })

export default {
  // plugins: [ pro ],
  // config: {
  //   classes: generateClasses({
  //     global: {
  //       label: 'dsv1__Input__Wrapper__Label',
  //       help: 'dsv1__Input__Help',
  //       messages: 'dsv1__Input__Messages',
  //       message: 'dsv1__Input__Messages__Message',
  //     },
  //     tel: {
  //       outer: 'dsv1__Input mb-2',
  //       wrapper: 'dsv1__Input__Wrapper',
  //       inner: 'dsv1__Input__Wrapper__Inner',
  //       input: 'dsv1__Input__Wrapper__Inner__Input',
  //     },
  //     text: {
  //       outer: 'dsv1__Input mb-2',
  //       wrapper: 'dsv1__Input__Wrapper',
  //       inner: 'dsv1__Input__Wrapper__Inner',
  //       input: 'dsv1__Input__Wrapper__Inner__Input',
  //     },
  //     number: {
  //       outer: 'dsv1__Input mb-2',
  //       wrapper: 'dsv1__Input__Wrapper',
  //       inner: 'dsv1__Input__Wrapper__Inner',
  //       input: 'dsv1__Input__Wrapper__Inner__Input',
  //     },
  //     textarea: {
  //       outer: 'dsv1__Input mb-2',
  //       wrapper: 'dsv1__Input__Wrapper',
  //       inner: 'dsv1__Input__Wrapper__Inner',
  //       input: 'dsv1__Input__Wrapper__Inner__Input',
  //     },
  //   })
  // }
}