import { createTRPCNuxtClient, httpBatchLink } from 'trpc-nuxt/client'
import type { AppRouter } from '~/server/trpc/routers'
import { loggerLink } from '@trpc/client'
export default defineNuxtPlugin(() => {
  const headers = useRequestHeaders();
  // const { csrf } = useCsrf();
  /**
   * createTRPCNuxtClient adds a `useQuery` composable
   * built on top of `useAsyncData`.
   */
  const client = createTRPCNuxtClient<AppRouter>({
    links: [
      loggerLink({
        enabled: (opts) =>
          (process.env.NODE_ENV === 'development' &&
            typeof window !== 'undefined') ||
          (opts.direction === 'down' && opts.result instanceof Error),
      }),
      httpBatchLink({
        url: '/api/trpc',
        headers() {
          return {
            ...headers,
            // "csrf-token": csrf
          };
        },
      }),
    ],
  })

  return {
    provide: {
      client,
    },
  }
})