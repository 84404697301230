import { defineNuxtPlugin } from '#app'
import Oruga, { useProgrammatic }  from '@oruga-ui/oruga-next'
const { oruga } = useProgrammatic();
// import '@oruga-ui/oruga-next/dist/oruga.css'

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp
    .use(Oruga)
  return {
    provide: {
      oruga,
    }
  }
})
  